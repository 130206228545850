import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./GraphMinorNodeModal.css"; // Reuse the CSS for modal styling
import { getFiles } from "../misc/helpers";
import FileBrowser from "./modals/FileBrowser";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface GraphMinorNodeModalProps {
  onSave: (config: {
    datafile: string[];
    rowcount: string;
    outputFolder: string;
  }) => void;
  onClose: () => void;
  open: boolean; // Add open prop
}

const GraphMinorNodeModal: React.FC<GraphMinorNodeModalProps> = ({
  onSave,
  onClose,
  open,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]); // Selected files from S3
  const [errors, setErrors] = useState({
    selectedColumn: "",
    outputDirectory: "",
  });
  const [classifyColumn, setClassifyColumn] = useState("");
  const [outputDirectory, setOutputDirectory] = useState("");

  const handleSave = () => {
    onSave({
      datafile: selectedFiles,
      rowcount: classifyColumn,
      outputFolder: outputDirectory,
    });
    onClose();
  };

  console.log("graph minor node modal open", open);
  if (!open) return null; // Return null if not open

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <p className="modal-txt">
          {selectedFiles.length > 0
            ? `Selected files: ${selectedFiles.join(", ")}`
            : "No files selected"}
        </p>
      </DialogTitle>
      <DialogContent>
        <FileBrowser
          onFileSelection={(files) => {
            setSelectedFiles(files);
          }}
          style={{ height: "300px" }}
        />

        <div className="form-group">
          <label
            htmlFor="classifyColumn"
            style={styles.label as React.CSSProperties}
          >
            Select Column to Classify
          </label>
          <input
            type="text"
            id="classifyColumn"
            name="classifyColumn"
            placeholder="Select Column to Classify"
            value={classifyColumn}
            required
            onChange={(e) => setClassifyColumn(e.target.value)}
            style={{ border: "1px solid black", marginLeft: 0 }}
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="outputDirectory"
            style={styles.label as React.CSSProperties}
          >
            Output Directory
          </label>
          <input
            type="text"
            id="outputDirectory"
            name="outputDirectory"
            placeholder="Output Directory"
            value={outputDirectory}
            required
            onChange={(e) => setOutputDirectory(e.target.value)}
            style={{ border: "1px solid black", marginLeft: 0 }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={selectedFiles.length < 1}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  label: {
    textAlign: "left",
    width: "250px", // Adjust this as needed
    marginRight: "10px",
    color: "black",
  },
};

export default GraphMinorNodeModal;
