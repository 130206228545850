import Swal from "sweetalert2";
export function showError(ex: any) {
  const message = parseErrorString(ex);
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: message,
  });
}

export function parseErrorString(ex: any) {
  console.log(ex, "ex");
  if (ex.message) {
    return ex.message;
  }
  return "An error occurred";
}
