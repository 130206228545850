import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./AnalysisConfigNodeModal.css"; // Reuse the CSS for modal styling
import { getFiles } from "../misc/helpers";
import FileBrowser from "./modals/FileBrowser";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
interface AnalysisConfigNodeModalTypes {
  forwardReads: string;
  reverseReads: string;
  dbsLibrary: string;
  outputDirectory: string;
  fastqDirectory: string;
}
interface AnalysisConfigNodeModalProps {
  onSave: (config: AnalysisConfigNodeModalTypes) => void;
  onClose: () => void;
  open: boolean; // Add open prop
}

const AnalysisConfigNodeModal: React.FC<AnalysisConfigNodeModalProps> = ({
  onSave,
  onClose,
  open,
}) => {
  const [regexForwardRead, setRegexForwardRead] = useState("");
  const [regexReverseRead, setRegexReverseRead] = useState("");
  const [databaseSelection, setDatabaseSelection] = useState("");
  const [outputLocation, setOutputLocation] = useState("");
  const [fastqDirectory, setfastqDirectory] = useState("");

  const handleSave = () => {
    onSave({
      forwardReads: regexForwardRead,
      reverseReads: regexReverseRead,
      dbsLibrary: databaseSelection,
      outputDirectory: outputLocation,
      fastqDirectory,
    });
    onClose();
  };

  if (!open) return null; // Return null if not open

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* <div className="form-group">
              <label htmlFor="fastqDirectory" style={styles.label}>
                FastQ Directory Name
              </label>
              <input
                type="text"
                id="fastqDirectory"
                name="fastqDirectory"
                placeholder="FastQ Directory Name"
                value={fastqDirectory}
                required
                onChange={(e) => setfastqDirectory(e.target.value)}
                style={{ border: "1px solid black", marginLeft: 0 }}
              />
            </div> */}
            <FileBrowser
              onFileSelection={(files) => {
                if (files.length > 0) {
                  setfastqDirectory(files[0]);
                }
              }}
              checkSelectable={(file) => !!file.isDir}
              filterFiles={(files) => files.filter((file) => !!file.isDir)}
              style={{ height: "200px" }}
              hideSearch
            />
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label htmlFor="regexForwardRead" style={styles.label}>
                Forward Reads
              </label>
              <input
                type="text"
                id="regexForwardRead"
                name="regexForwardRead"
                placeholder="Regex Forward Read"
                value={regexForwardRead}
                required
                onChange={(e) => setRegexForwardRead(e.target.value)}
                style={{ border: "1px solid black", marginLeft: 0 }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label htmlFor="regexReverseRead" style={styles.label}>
                {" "}
                Reverse Reads
              </label>
              <input
                type="text"
                id="regexReverseRead"
                name="regexReverseRead"
                placeholder="Regex Reverse Read"
                value={regexReverseRead}
                required
                onChange={(e) => setRegexReverseRead(e.target.value)}
                style={{ border: "1px solid black", marginLeft: 0 }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={styles.formGroup}>
              <label
                htmlFor="database"
                style={{ ...styles.label, ...{ marginTop: 5 } }}
              >
                DBS Library
              </label>
              <select
                id="database"
                name="database"
                value={databaseSelection}
                onChange={(e) => setDatabaseSelection(e.target.value)}
                required
                style={styles.select}
              >
                <option value="" disabled>
                  Select a database
                </option>
                <option value="Silva">Silva</option>
                <option value="HOMD">HOMD</option>
              </select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="form-group">
              <label htmlFor="outputLocation" style={styles.label}>
                Output Directory Name
              </label>
              <input
                type="text"
                id="outputLocation"
                name="outputLocation"
                placeholder="Output Location"
                value={outputLocation}
                required
                onChange={(e) => setOutputLocation(e.target.value)}
                style={{ border: "1px solid black", marginLeft: 0 }}
              />
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles: any = {
  formGroup: {
    // display: "flex",
    alignItems: "center",
  },
  label: {
    textAlign: "left",
    width: "250px", // Adjust this as needed
    marginRight: "10px",
    color: "black",
  },
  select: {
    padding: "5px",
    flexGrow: 1,
  },
  button: {
    padding: "5px 10px",
    marginTop: "10px",
  },
};

export default AnalysisConfigNodeModal;
