import React from "react";

interface IDropOverlayProps {
  id: string;
}
export default function DropOverlay(props: IDropOverlayProps) {
  return (
    <div
      id={props.id}
      style={{
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000,
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "8px",
          textAlign: "center",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <p style={{ margin: 0, fontSize: "1.2em", color: "#666" }}>
          Drop files here to upload
        </p>
      </div>
    </div>
  );
}
