import {
  Table,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Button,
} from "@mui/material";
import { TableContainer } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { parseErrorString } from "../utils/alertUtils";

interface ICSVViewerProps {
  url: string;
}
export default function CSVViewer(props: ICSVViewerProps) {
  const [showFull, setShowFull] = useState(false);
  let q = useQuery({
    queryKey: ["textFile", props.url],
    queryFn: async () => {
      let txt = await fetch(props.url).then((res) => res.text());
      let lst = txt.split("\n").map((line) => line.split(","));
      return lst;
    },
  });

  let isShowFullApplicable = q.data && q.data[0].length > 50;

  let lstToShow = (showFull ? q.data : q.data?.slice(0, 50)) || [];
  let remainingRows = (q.data?.length || 0) - lstToShow.length;

  return (
    <div style={{ width: "100%", minHeight: "50vh" }}>
      {q.isError && (
        <div style={{ color: "red" }}>
          Error loading file {parseErrorString(q.error)}
        </div>
      )}
      {q.isLoading && <div>Loading file...</div>}
      {isShowFullApplicable && (
        <>
          <span>
            {showFull ? (
              ""
            ) : (
              <span>{remainingRows} more records are not shown &nbsp;</span>
            )}
          </span>

          <Button onClick={() => setShowFull(!showFull)}>
            {showFull ? "Show less" : "Show all"}
          </Button>
        </>
      )}
      {q.data && (
        <TableContainer component={Paper} style={{ maxHeight: "50vh" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableBody>
              {lstToShow.map((row, index) => (
                <TableRow key={index}>
                  {row.map((cell, index2) => (
                    <TableCell
                      style={{ fontWeight: index === 0 ? "bold" : "normal" }}
                      key={index2}
                    >
                      {cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
