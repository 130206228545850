import { useQuery } from "@tanstack/react-query";
import CSVViewer from "../../common/CSVViewer";
import { openGlobalDialog } from "../../common/GlobalDialog";
import DownloadIcon from "@mui/icons-material/Download";
import { saveAs } from "file-saver";
import ReactQueryLoadingError from "../../common/ReactQueryLoadingError";
import TextFileViewer from "../../common/TextFileViewer";
import { ApiService } from "../../services/ApiService";
import "./file-preview.css";
import { Button, Icon } from "@mui/material";
import { useState } from "react";
import { setGlobalLoadingProgress } from "../../common/GlobalLoading";
import { withLoadingError } from "../../common/GlobalLoading";

const PREVIEW_SIZE_LIMIT_IN_MB = 20;
interface IFilePreviewDialogProps {
  fileURL?: string;
  s3ID?: string;
  name: string;
}
export default function FilePreviewDialog(props: IFilePreviewDialogProps) {
  const extension = props.name.split(".").pop()?.toLowerCase();
  const [progress, setProgress] = useState<number | undefined>();
  let fileType = "";
  if (["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(extension || "")) {
    fileType = "image";
  } else if (["txt", "ipynb", "py", "r", "gb"].includes(extension || "")) {
    fileType = "text";
  } else if (extension === "csv") {
    fileType = "csv";
  } else if (extension === "pdf") {
    fileType = "pdf";
  }
  let q = useQuery({
    queryKey: ["filePreview", props.s3ID],
    queryFn: async () => {
      if (!props.fileURL && !props.s3ID) {
        throw new Error("No fileURL or ID provided");
      }
      if (!props.s3ID) {
        return props.fileURL;
      }
      if (!fileType) {
        return "NONE";
      }
      let meta = await ApiService.getFileMetadata(props.s3ID);
      if (!meta.contentLength) {
        throw new Error("Can not get file metadata");
      }
      if (meta.contentLength > PREVIEW_SIZE_LIMIT_IN_MB * 1024 * 1024) {
        return "LARGE";
      }

      let res = await ApiService.downloadSingleFile(props.s3ID, (progress) => {
        setProgress(progress);
      });
      const blob = new Blob([res.data], { type: res.headers["content-type"] });
      return URL.createObjectURL(blob);
    },
    gcTime: 0,
  });

  let url = q.data;

  return (
    <div style={{ minHeight: "500px" }}>
      <ReactQueryLoadingError q={q} progress={progress} />
      {url && (
        <Button
          startIcon={<DownloadIcon />}
          variant="contained"
          color="primary"
          component="a"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            let link = ApiService.prepareDownloadLink(props.s3ID || "");
            window.open(link, "_blank");

            // if (url === "LARGE" || url === "NONE") {
            //   withLoadingError(async () => {
            //     setGlobalLoadingProgress(0);
            //     let res = await ApiService.downloadSingleFile(
            //       props.s3ID || "",
            //       (p) => {
            //         setGlobalLoadingProgress(p);
            //       }
            //     );
            //     saveAs(res.data, props.name || "");
            //   });
            // } else {
            //   saveAs(url || "", props.name || "");
            // }
          }}
        >
          Download {props.name}
        </Button>
      )}

      {url &&
        url !== "LARGE" &&
        (() => {
          if (fileType === "image") {
            return (
              <img
                src={url}
                alt={props.name}
                style={{ maxWidth: "100%", maxHeight: "80vh" }}
              />
            );
          }

          if (fileType === "text") {
            return <TextFileViewer url={url} />;
          }

          if (fileType === "csv") {
            return <CSVViewer url={url} />;
          }

          if (extension === "pdf") {
            return (
              <embed
                title="PDF Viewer"
                style={{ width: "100%", height: "80vh" }}
                src={url}
              />
            );
          }

          // Fallback for unsupported files
          return (
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                color: "#666",
              }}
            >
              Preview not available for this file type.
              <br />
            </div>
          );
        })()}
      {url === "LARGE" && (
        <div
          style={{
            padding: "20px",
            textAlign: "center",
            color: "#666",
          }}
        >
          File is too large to preview.
          <br />
        </div>
      )}
    </div>
  );
}

export function openFilePreviewDialog(props: IFilePreviewDialogProps) {
  openGlobalDialog({
    title: (
      <div style={{ textAlign: "center", width: "100%" }}>
        {props.name || "File Preview"}
      </div>
    ),
    body: <FilePreviewDialog {...props} />,
    dialogProps: {
      fullWidth: true,
      maxWidth: "lg",
    },
  });
}
