import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";
import authHeader from "../../services/auth-header";
import "./DocLLM.css";

interface Field {
  id: string;
  name: string;
  description: string;
  type: string;
}

interface Category {
  id: string;
  name: string;
  description: string;
  fields: Field[];
}

interface Result {
  category: string;
  field: string;
  value: string;
  source: string;
  confidence: number;
}

export default function DocLLM(): JSX.Element {
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [categories, setCategories] = useState<Category[]>([
    {
      id: uuidv4(),
      name: "",
      description: "",
      fields: [],
    },
  ]);
  const [step, setStep] = useState<number>(0);
  const [results, setResults] = useState<Result[]>([]); // State to store the results
  const [JSONResult, setJSONResult] = useState<any>([{}]); // State to store the JSON results

  const MAX_TOTAL_COUNT = 25;

  const API_URL = process.env.REACT_APP_BACKEND + "/api/llmparser";

  const handleUploadFile = (file: File) => {
    setDroppedFile(file);
    setStep(1);
  };

  const handleDrop = (item: any) => {
    const droppedFiles = item.files;
    if (droppedFiles.length > 0) {
      setDroppedFile(droppedFiles[0]);
      setStep(1);
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop: (item: any) => handleDrop(item),
    canDrop: (item: any) => {
      const files = item.files;
      if (files.length === 1) {
        const file = files[0];
        return file.type === "text/plain";
      }
      return false;
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const addCategory = () => {
    if (categories.length + countTotalFields() <= MAX_TOTAL_COUNT) {
      setCategories([
        ...categories,
        { id: uuidv4(), name: "", description: "", fields: [] },
      ]);
    } else {
      alert("Adding a new category exceeds the maximum total count.");
    }
  };

  const removeCategory = (categoryId: string) => {
    setCategories(categories.filter((category) => category.id !== categoryId));
  };

  const handleCategoryChange = (
    categoryId: string,
    field: string,
    value: string
  ) => {
    setCategories(
      categories.map((category) =>
        category.id === categoryId ? { ...category, [field]: value } : category
      )
    );
  };

  const addField = (categoryId: string) => {
    const category = categories.find((cat) => cat.id === categoryId);
    if (
      category &&
      category.fields.length + countTotalCategories() <= MAX_TOTAL_COUNT
    ) {
      setCategories(
        categories.map((cat) =>
          cat.id === categoryId
            ? {
                ...cat,
                fields: [
                  ...cat.fields,
                  { id: uuidv4(), name: "", description: "", type: "string" },
                ],
              }
            : cat
        )
      );
    } else {
      alert(
        "Adding a new field in this category exceeds the maximum total count."
      );
    }
  };

  const countTotalFields = () => {
    return categories.reduce(
      (total, category) => total + category.fields.length,
      0
    );
  };

  const countTotalCategories = () => {
    return categories.length;
  };

  const removeField = (categoryId: string, fieldId: string) => {
    setCategories(
      categories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              fields: category.fields.filter((field) => field.id !== fieldId),
            }
          : category
      )
    );
  };

  const handleFieldChange = (
    categoryId: string,
    fieldId: string,
    fieldName: string,
    value: string
  ) => {
    setCategories(
      categories.map((category) =>
        category.id === categoryId
          ? {
              ...category,
              fields: category.fields.map((field) =>
                field.id === fieldId ? { ...field, [fieldName]: value } : field
              ),
            }
          : category
      )
    );
  };

  const handleSubmit = () => {
    if (droppedFile && categories.length > 0) {
      const formattedData = categories.map((category) => ({
        name: category.name,
        description: category.description,
        fields: category.fields.map((field) => ({
          name: field.name,
          description: field.description,
          type: field.type,
        })),
      }));

      const formData = new FormData();
      formData.append("txtFile", droppedFile);
      formData.append("searchParametersJSON", JSON.stringify(formattedData));

      axios
        .post(API_URL, formData, {
          headers: { "Content-Type": "multipart/form-data", ...authHeader() },
        })
        .then((response) => {
          console.log(JSON.stringify(response.data));
          const responseData = response.data;

          // Process the response data to populate the table
          const resultArray: Result[] = [];
          const category = responseData.type; // Extract the category from response data
          const fields = responseData.fields;

          Object.keys(fields).forEach((fieldName) => {
            const fieldData = fields[fieldName];
            resultArray.push({
              category: category,
              field: fieldName,
              value: fieldData.value,
              source: fieldData.source,
              confidence: fieldData.confidence * 100, // Convert to percentage
            });
          });
          console.log(resultArray); // Add this line to log the results array
          setResults(resultArray);
          setJSONResult(responseData);
          setStep(2);
        })
        .catch((error) => {
          console.error("Error getting parser:", error);
        });
    } else {
      alert("Please upload a file and add categories before submitting.");
    }
  };

  const handleClickToUpload = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".txt";
    fileInput.addEventListener("change", (event) => {
      const fileList = (event.target as HTMLInputElement).files;
      if (fileList && fileList.length > 0) {
        const selectedFile = fileList[0];
        if (selectedFile.type === "text/plain") {
          setDroppedFile(selectedFile);
          setStep(1);
        } else {
          alert("Please select a .txt file.");
        }
      }
    });
    fileInput.click();
  };

  return (
    <div className="container">
      {step === 0 && (
        <div className="step active">
          <div
            onClick={handleClickToUpload}
            ref={drop}
            className={`drop-target ${isOver ? "hover" : ""}`}
            style={{
              border: "2px dashed #99ddcc",
              borderRadius: "4px",
              padding: "20px",
              textAlign: "center",
              margin: "20px auto 0 auto",
              width: "70%",
              height: "18rem",
              fontSize: "larger",
            }}
          >
            <div className="dnd-text-container">
              <DriveFolderUploadIcon fontSize="large" />
              {isOver
                ? "Drop here"
                : "Drag and drop a txt file here or click to upload"}
            </div>
          </div>
        </div>
      )}

      {step === 1 && (
        <div className="step active">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() => setStep(0)}
              sx={{
                backgroundColor: "#99ddcc",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "#99ddcc",
                },
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={addCategory}
              sx={{
                backgroundColor: "black",
                color: "#99ddcc",
                "&:hover": {
                  backgroundColor: "#99ddcc",
                  color: "black",
                },
              }}
            >
              Add Category
            </Button>
          </div>

          {categories.map((category) => (
            <div
              key={category.id}
              style={{
                marginTop: "20px",
                border: "1px solid #ccc",
                padding: "10px",
              }}
            >
              <TextField
                label="Category Name"
                variant="outlined"
                value={category.name}
                onChange={(e) =>
                  handleCategoryChange(category.id, "name", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Category Description"
                variant="outlined"
                value={category.description}
                onChange={(e) =>
                  handleCategoryChange(
                    category.id,
                    "description",
                    e.target.value
                  )
                }
                fullWidth
                margin="normal"
              />
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => addField(category.id)}
                sx={{
                  backgroundColor: "black",
                  color: "#99ddcc",
                  "&:hover": {
                    backgroundColor: "#99ddcc",
                    color: "black",
                  },
                }}
              >
                Add Field
              </Button>
              {category.fields.map((field) => (
                <div
                  key={field.id}
                  style={{
                    marginTop: "10px",
                    border: "1px solid #ccc",
                    padding: "10px",
                  }}
                >
                  <TextField
                    label="Field Name"
                    variant="outlined"
                    value={field.name}
                    onChange={(e) =>
                      handleFieldChange(
                        category.id,
                        field.id,
                        "name",
                        e.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                  <TextField
                    label="Field Description"
                    variant="outlined"
                    value={field.description}
                    onChange={(e) =>
                      handleFieldChange(
                        category.id,
                        field.id,
                        "description",
                        e.target.value
                      )
                    }
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<RemoveIcon />}
                    onClick={() => removeField(category.id, field.id)}
                    sx={{
                      backgroundColor: "#FA3E3E",
                      color: "black",
                      "&:hover": {
                        backgroundColor: "black",
                        color: "#FA3E3E",
                      },
                    }}
                  >
                    Remove Field
                  </Button>
                </div>
              ))}
              <Button
                variant="contained"
                color="error"
                startIcon={<RemoveIcon />}
                onClick={() => removeCategory(category.id)}
                sx={{
                  backgroundColor: "#FA3E3E",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "#FA3E3E",
                  },
                }}
              >
                Remove Category
              </Button>
            </div>
          ))}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#99ddcc",
              color: "black",
              "&:hover": {
                backgroundColor: "black",
                color: "#99ddcc",
              },
            }}
          >
            Submit
          </Button>
        </div>
      )}

      {step === 2 && (
        <div className="step active">
          <h2>Results</h2>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              marginTop: "20px",
            }}
          >
            <thead>
              <tr>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Category
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Field
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Value
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Source
                </th>
                <th style={{ border: "1px solid #ccc", padding: "8px" }}>
                  Confidence Score [%]
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map((result, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {result.category}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {result.field}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {result.value}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {result.source}
                  </td>
                  <td style={{ border: "1px solid #ccc", padding: "8px" }}>
                    {result.confidence.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            <p className="JSON-Result">{JSON.stringify(JSONResult)}</p>
          </div>
        </div>
      )}
    </div>
  );
}
