import { FileArray, FileData } from "chonky";
import { ApiService } from "../../services/ApiService";

export function getFileExtension(fileName: string): string | null {
  const lastDotIndex = fileName.lastIndexOf(".");

  // If no dot is found
  if (lastDotIndex === -1) {
    return null;
  }

  // If the dot is the first character and there's no extension, return null
  if (lastDotIndex === 0 && fileName.length === 1) {
    return null;
  }

  return fileName.slice(lastDotIndex + 1);
}

export const getFileColor: { [key: string]: string } = {
  r: "rgba(255, 0, 0, 1)",
  php: "rgba(92, 198, 83, 1)",
  js: "rgba(52, 152, 219, 1)",
  html: "rgba(241, 196, 15, 1)",
  css: "rgba(46, 204, 113, 1)",
  py: "rgba(155, 89, 182, 1)",
  java: "rgba(231, 76, 60, 1)",
  c: "rgba(26, 188, 156, 1)",
  cpp: "rgba(142, 68, 173, 1)",
  rb: "rgba(44, 62, 80, 1)",
  swift: "rgba(230, 126, 115, 1)",
  go: "rgba(52, 152, 219, 1)",
  rs: "rgba(241, 196, 15, 1)",
  scala: "rgba(46, 204, 113, 1)",
  clj: "rgba(155, 89, 182, 1)",
  kt: "rgba(231, 76, 60, 1)",
  dart: "rgba(26, 188, 156, 1)",
  ex: "rgba(142, 68, 173, 1)",
  cr: "rgba(44, 62, 80, 1)",
  nim: "rgba(230, 126, 115, 1)",
  jl: "rgba(52, 152, 219, 1)",
  elm: "rgba(241, 196, 15, 1)",
  elixir: "rgba(46, 204, 113, 1)",
  groovy: "rgba(155, 89, 182, 1)",
  deno: "rgba(231, 76, 60, 1)",
  zig: "rgba(26, 188, 156, 1)",
  v: "rgba(142, 68, 173, 1)",
  d: "rgba(44, 62, 80, 1)",
  ada: "rgba(230, 126, 115, 1)",
  vhdl: "rgba(52, 152, 219, 1)",
  verilog: "rgba(241, 196, 15, 1)",
  sol: "rgba(46, 204, 113, 1)",
  ipynb: "rgba(155, 89, 182, 1)",
  ts: "rgba(231, 76, 60, 1)",
  jsx: "rgba(26, 188, 156, 1)",
  tsx: "rgba(142, 68, 173, 1)",
  vue: "rgba(44, 62, 80, 1)",
  svelte: "rgba(230, 126, 115, 1)",
  json: "rgba(52, 152, 219, 1)",
  yaml: "rgba(241, 196, 15, 1)",
  toml: "rgba(46, 204, 113, 1)",
  xml: "rgba(155, 89, 182, 1)",
  csv: "rgba(231, 76, 60, 1)",
  sql: "rgba(26, 188, 156, 1)",
  md: "rgba(142, 68, 173, 1)",
  txt: "rgba(44, 62, 80, 1)",
  pdf: "rgba(230, 126, 115, 1)",
  docx: "rgba(52, 152, 219, 1)",
  xlsx: "rgba(241, 196, 15, 1)",
  pptx: "rgba(46, 204, 113, 1)",
  zip: "rgba(155, 89, 182, 1)",
  tar: "rgba(231, 76, 60, 1)",
  gz: "rgba(26, 188, 156, 1)",
  dmg: "rgba(142, 68, 173, 1)",
  exe: "rgba(44, 62, 80, 1)",
  dll: "rgba(230, 126, 115, 1)",
  so: "rgba(52, 152, 219, 1)",
  o: "rgba(241, 196, 15, 1)",
  a: "rgba(46, 204, 113, 1)",
  lib: "rgba(155, 89, 182, 1)",
  dylib: "rgba(231, 76, 60, 1)",
  pdb: "rgba(26, 188, 156, 1)",
  pch: "rgba(142, 68, 173, 1)",
  framework: "rgba(44, 62, 80, 1)",
  app: "rgba(230, 126, 115, 1)",
  pkg: "rgba(52, 152, 219, 1)",
  xpi: "rgba(241, 196, 15, 1)",
  crx: "rgba(46, 204, 113, 1)",
  vsix: "rgba(155, 89, 182, 1)",
  nupkg: "rgba(231, 76, 60, 1)",
  gem: "rgba(26, 188, 156, 1)",
  egg: "rgba(142, 68, 173, 1)",
  whl: "rgba(44, 62, 80, 1)",
  jar: "rgba(230, 126, 115, 1)",
  war: "rgba(52, 152, 219, 1)",
  ear: "rgba(241, 196, 15, 1)",
  aar: "rgba(46, 204, 113, 1)",
  apk: "rgba(155, 89, 182, 1)",
  aab: "rgba(231, 76, 60, 1)",
  ipa: "rgba(26, 188, 156, 1)",
  xcframework: "rgba(142, 68, 173, 1)",
  xarchive: "rgba(44, 62, 80, 1)",
  gitignore: "rgba(230, 126, 115, 1)",
  gitattributes: "rgba(52, 152, 219, 1)",
  gitmodules: "rgba(241, 196, 15, 1)",
  gitkeep: "rgba(46, 204, 113, 1)",
  gitconfig: "rgba(155, 89, 182, 1)",
  rar: "rgba(123, 121, 182, 1)",
  folder: "rgb(187, 187, 187)",
};
export const getfileColor = (key: string) => {
  let getfileext: string = getFileExtension(key) || "txt";

  return getFileColor[getfileext];
};

export type FileTree = FileData & {
  prefix: string;
};

export async function getAllFilesCached(
  io: {
    refetch?: boolean;
  } = {}
): Promise<string[]> {
  if ((window as any)?.filesCached && !io.refetch) {
    return (window as any).filesCached;
  }
  let initialFiles: string[] = await ApiService.getAllFiles();

  let files = initialFiles.map((file) => file);
  (window as any).filesCached = files;
  return files;
}

export async function getFiles(
  prefix: string = "",
  io: {
    refetch?: boolean;
    search?: string;
    returnFlatTree?: boolean;
  } = {}
): Promise<FileData[]> {
  if (prefix === "root") {
    prefix = "";
  }
  prefix = prefix.replace(/\/$/, "");

  // console.table({ prefix, ...io });

  let initialFiles = await getAllFilesCached({
    refetch: io.refetch,
  });

  if (io.returnFlatTree) {
    return initialFiles.map((file) => ({
      id: file,
      isDir: false,
      name: file,
      color: getfileColor(file),
    }));
  }

  let node = createTree(initialFiles);

  if (prefix) {
    let parts = prefix.split("/");
    let current = node;
    for (const part of parts) {
      current = current[part] || {};
    }
    node = current;
  }
  if (io.search) {
    // initialFiles = initialFiles.filter((file) =>
    //   new RegExp(io.search || "", "i").test(file)
    // );
    let lstFiles = [];
    // let node = createTree(initialFiles);
    lstFiles = getFlatTreeNested(node);

    return lstFiles
      .filter((file) => new RegExp(io.search || "", "i").test(file))
      .map((file) => ({
        id: file,
        isDir: file.endsWith("/"),
        name: file,
        color: file.endsWith("/") ? "rgb(187, 187, 187)" : getfileColor(file),
      }));
  }

  let initialKeys = Object.keys(node).map((key) => {
    let val = node[key];
    let isDir = Object.keys(val).length > 0;
    return {
      id: isDir
        ? `${prefix}${prefix ? "/" : ""}${key}/`
        : `${prefix}${prefix ? "/" : ""}${key}`,
      isDir: isDir,
      name: key,
      color: isDir ? "rgb(187, 187, 187)" : getfileColor(key),
    };
  });
  // console.log(initialKeys, "initialKeys", prefix);

  return initialKeys || [];
}

export type StrNode = {
  [key: string]: StrNode;
};

export function createTree(files: string[]): StrNode {
  const root: StrNode = {};

  for (const file of files) {
    const parts = file.split("/");
    let current = root;

    for (const part of parts) {
      if (!current[part]) {
        current[part] = {};
      }
      current = current[part];
    }
  }

  return root;
}

export function getFlatTreeNested(
  node: StrNode,
  prefix: string = ""
): string[] {
  let files: string[] = [];
  for (const key of Object.keys(node)) {
    const isDir = Object.keys(node[key]).length > 0;
    files.push(`${prefix}${prefix ? "/" : ""}${key}${isDir ? "/" : ""}`);
    files.push(
      ...getFlatTreeNested(node[key], `${prefix}${prefix ? "/" : ""}${key}`)
    );
  }
  return files;
}

// export function filterNodeNested(node: StrNode, search: string) {
//   let reg = new RegExp(search || "", "i");
//   for (let key in node) {
//     let childNode = node[key];
//     let isDir = Object.keys(childNode).length > 0;
//     if (isDir) {
//       filterNodeNested(childNode, search);
//     } else {
//       if (!reg.test(key)) {
//         delete node[key];
//       }
//     }
//   }
//   return node;
// }
