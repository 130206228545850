import { CircularProgress, LinearProgress } from "@mui/material";
import { Store } from "pullstate";
import { showError } from "../utils/alertUtils";

const LoadingStore = new Store<{
  progress: number | undefined;
}>({
  progress: undefined,
});

export function setGlobalLoadingProgress(progress: number | undefined) {
  LoadingStore.update((s) => {
    s.progress = progress;
  });
}

interface IGlobalLoadingProps {}
export default function GlobalLoading(props: IGlobalLoadingProps) {
  let progress = LoadingStore.useState((s) => s.progress);
  return (
    <div
      id="global-loading"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      {progress !== undefined ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "50%",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={progress}
            style={{ flexGrow: 1 }}
          />
          <span>{Math.round(progress)}%</span>
        </div>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export function showGlobalLoading() {
  const globalLoading = document.getElementById("global-loading");
  if (globalLoading) {
    globalLoading.style.display = "flex";
  }
}

export function hideGlobalLoading() {
  const globalLoading = document.getElementById("global-loading");
  if (globalLoading) {
    globalLoading.style.display = "none";
  }
}

export async function withLoadingError<T>(fn: () => Promise<T>) {
  showGlobalLoading();
  try {
    return await fn();
  } catch (error) {
    showError(error);
  } finally {
    hideGlobalLoading();
  }
}
