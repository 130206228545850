import { useQuery } from "@tanstack/react-query";
import React from "react";
import { parseErrorString } from "../utils/alertUtils";

interface ITextFileViewerProps {
  url: string;
}
export default function TextFileViewer(props: ITextFileViewerProps) {
  let q = useQuery({
    queryKey: ["textFile", props.url],
    queryFn: () => fetch(props.url).then((res) => res.text()),
  });
  return (
    <div style={{ width: "100%", minHeight: "50vh" }}>
      {q.isError && (
        <div style={{ color: "red" }}>
          Error loading file {parseErrorString(q.error)}
        </div>
      )}
      {q.isLoading && <div>Loading file...</div>}
      {q.data && (
        <textarea
          readOnly
          style={{ width: "100%", height: "50vh" }}
          value={q.data}
        />
      )}
    </div>
  );
}
