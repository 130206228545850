import axios from "axios";
import authHeader from "./auth-header";

export class ApiService {
  static async getAllFiles(): Promise<string[]> {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/s3/list`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  static async getFileMetadata(key: string) {
    let fileName = key.split("/").pop()!;
    let subfolderPath = key.split("/").slice(0, -1).join("/");
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/s3/metadata/${fileName}?subfolderPath=${subfolderPath}`,
      {
        headers: authHeader(),
      }
    );
    return response.json();
  }

  static prepareDownloadLink(key: string) {
    let fileName = key.split("/").pop()!;
    let subfolderPath = key.split("/").slice(0, -1).join("/");
    return `${process.env.REACT_APP_BACKEND}/api/s3/download/${fileName}?subfolderPath=${subfolderPath}`;
  }

  static async downloadSingleFile(
    key: string,
    onProgress?: (progress: number) => void
  ) {
    let fileName = key.split("/").pop()!;
    let subfolderPath = key.split("/").slice(0, -1).join("/");
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/api/s3/download/${fileName}?subfolderPath=${subfolderPath}`,
      {
        headers: authHeader(),
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          if (onProgress && progressEvent.total) {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress(percentCompleted);
          }
        },
      }
    );

    let contentType =
      response.headers["content-type"] || "application/octet-stream";
    if (key.endsWith(".pdf")) {
      contentType = "application/pdf";
    }

    return {
      data: response.data,
      headers: {
        "content-type": contentType,
      },
    };
  }

  static async downloadMultipleFiles(subfolderPath: string) {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/s3/downloadAll?subfolderPath=${subfolderPath}`,
      {
        headers: authHeader(),
      }
    );
    const blob = await response.blob();
    let contentType =
      response.headers.get("content-type") || "application/octet-stream";
    return {
      data: blob,
      headers: {
        "content-type": contentType,
      },
    };
  }

  static async uploadFile(files: File[], subfolderPath: string) {
    let formData = new FormData();

    files.forEach((file) => {
      formData.append("files", file);
    });
    if (files.length === 0) {
      formData.append("files", new File([], "empty"));
    }
    formData.append("subfolderPath", subfolderPath);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/api/s3/upload`,
      formData,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }

  static async deleteFiles(keys: string[]) {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND}/api/s3/delete?${keys
        .map((key) => `fileNames=${key}`)
        .join("&")}`,
      {
        headers: authHeader(),
      }
    );
    return response.data;
  }

  static async renameFile(oldFileName: string, newFileName: string) {
    if (oldFileName === newFileName) {
      throw new Error("Old and new file names are the same");
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("oldFileName", oldFileName);
    urlencoded.append("newFileName", newFileName);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/api/s3/renameFile`,
      urlencoded,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  }

  static async renameFolder(oldFolderPrefix: string, newFolderPrefix: string) {
    if (oldFolderPrefix === newFolderPrefix) {
      throw new Error("Old and new folder prefixes are the same");
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("oldFolderPrefix", oldFolderPrefix);
    urlencoded.append("newFolderPrefix", newFolderPrefix);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/api/s3/renameFolder`,
      urlencoded,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  }

  static async createFolder(folderName: string) {
    if (!folderName) {
      throw new Error("Folder prefix cannot be empty");
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("folderName", folderName);
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/api/s3/createFolder`,
      urlencoded,
      {
        headers: {
          ...authHeader(),
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return response.data;
  }

  static async deleteFolder(folderName: string) {
    if (!folderName) {
      throw new Error("Folder name cannot be empty");
    }

    var urlencoded = new URLSearchParams();
    urlencoded.append("folderName", folderName);
    const response = await axios({
      url: `${process.env.REACT_APP_BACKEND}/api/s3/deleteFolder`,
      method: "DELETE",
      data: urlencoded,
      headers: {
        ...authHeader(),
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return response.data;
  }
}
