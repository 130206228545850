import React, { ComponentProps } from "react";
import { Store } from "pullstate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IGlobalModalProps {
  id: string;
  title: string | React.ReactNode;
  body: React.ReactNode;
  open: boolean;
  onClose: () => void;
  dialogProps?: Partial<ComponentProps<typeof Dialog>>;
}
const ConfirmStore = new Store<{ modals: IGlobalModalProps[] }>({
  modals: [],
});

export function GlobalDialogPanel() {
  let state = ConfirmStore.useState();
  return (
    <>
      {state.modals.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {item.open && <GlobalModal {...item} />}
          </React.Fragment>
        );
      })}
    </>
  );
}

function GlobalModal(props: IGlobalModalProps) {
  return (
    <Dialog
      open={props.open}
      maxWidth={"sm"}
      fullWidth
      onClose={() => {
        props.onClose();
      }}
      {...props.dialogProps}
      sx={{}}
    >
      {props.open && (
        <>
          {props.title && (
            <DialogTitle
              style={{ padding: 5, paddingLeft: 8 }}
              className="draggable-handle"
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {props.title}
                <div style={{ flexGrow: 1 }}></div>

                <IconButton
                  style={{ backgroundColor: "white" }}
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
          )}
          {props.body && (
            <DialogContent className="model-content" style={{ padding: 8 }}>
              {props.body}
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
}

export function openGlobalDialog(
  props: Omit<IGlobalModalProps, "open" | "onClose" | "id">
) {
  let id = Math.random().toString(36).substring(2, 15);

  function onClose() {
    ConfirmStore.update((s) => {
      s.modals = s.modals.filter((item) => item.id !== id);
    });
  }

  ConfirmStore.update((state) => ({
    modals: [
      ...state.modals,
      {
        ...props,
        open: true,
        id,
        onClose,
      },
    ],
  }));

  return {
    onClose,
  };
}
