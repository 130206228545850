import { Alert, CircularProgress } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  ChonkyActions,
  ChonkyFileActionData,
  FileBrowser as ChonkyFileBrowser,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
} from "chonky";
import { useMemo, useState } from "react";
import { openFilePreviewDialog } from "../../misc/FilePreviewDialog";
import { getFiles } from "../../misc/helpers";

interface IFileBrowserProps {
  onFileSelection?: (selectedFiles: string[]) => void;
  style?: React.CSSProperties;
  checkSelectable?: (file: FileData) => boolean;
  filterFiles?: (files: FileData[]) => FileData[];
  defaultSearch?: string;
  hideSearch?: boolean;
}
export default function FileBrowser(props: IFileBrowserProps) {
  let [prefix, setPrefix] = useState("");
  const [search, setSearch] = useState(props.defaultSearch || "");
  let q = useQuery({
    queryKey: ["modal-files", prefix, search],
    queryFn: async () => {
      console.log("querying", prefix, search);
      let files = await getFiles(prefix, { search });
      if (props.filterFiles) {
        files = props.filterFiles(files);
      }
      files = files.map((file) => {
        return {
          ...file,
          selectable: props.checkSelectable
            ? props.checkSelectable(file)
            : !file.isDir,
        };
      });
      return files;
    },
    gcTime: 0,
  });

  const folderChain = useMemo(() => {
    const parts = prefix.split("/");
    let accumulatedPath = "";
    const folderChain = parts.map((part: string, index: number) => {
      if (index < parts.length - 1) {
        accumulatedPath += part + "/";
      } else {
        accumulatedPath += part;
      }
      return { id: accumulatedPath, name: part, isDir: true };
    });
    return [{ id: "root", name: "Root", isDir: true }, ...folderChain];
  }, [prefix]);

  function onFileAction(action: ChonkyFileActionData) {
    console.log("onFileAction", action);
    if (action.id === ChonkyActions.OpenFiles.id) {
      let file = action.payload.targetFile;
      if (file?.isDir) {
        setPrefix(file.id === "root" ? "" : file.id);
      } else {
        openFilePreviewDialog({
          s3ID: file?.id,
          name: file?.name || "",
        });
      }
    } else if (action.id === ChonkyActions.ChangeSelection.id) {
      if (props.onFileSelection) {
        props.onFileSelection(Array.from(action.payload.selection));
      }
    }
  }

  return (
    <div style={{ height: "70vh", ...props.style }}>
      {q.isError && <Alert severity="error">Error: {q.error.message}</Alert>}
      {q.isLoading && <CircularProgress />}
      <ChonkyFileBrowser
        files={q.data || []}
        onFileAction={onFileAction}
        folderChain={folderChain}
        defaultFileViewActionId="enable_list_view"
        clearSelectionOnOutsideClick={false}
      >
        <FileNavbar />
        {!props.hideSearch && (
          <FileToolbar
            onSearchChange={(value) => {
              setSearch(value);
            }}
          />
        )}
        <FileList />
      </ChonkyFileBrowser>
    </div>
  );
}
