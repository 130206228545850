import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/WorkflowBuilder.css"; // You can style your component with CSS
import authHeader from "../../services/auth-header";
import axios from "axios";
import RunWorkflowButton from "../../material_ui/RunWorkflowButton";
import DataUploadModal from "./DataUploadModal";
import ScriptingNodeModal from "./ScriptingNodeModal";
import GraphMinorNodeModal from "./GraphMinorNodeModal";
import AnalysisConfigNodeModal from "./AnalysisConfigNodeModal";
import FileSelector from "./modals/FileSelector";
import { Tooltip } from "@mui/material";

interface Node {
  id: number;
  type: string;
  config: any; // Node configuration
  name: string; // Node name
}

const WorkflowBuilder: React.FC = () => {
  const [saveMessage, setSaveMessage] = useState<string | null>(null);
  const [nodes, setNodes] = useState<Node[]>([]); // To store the nodes in the middle area
  const [selectedNode, setSelectedNode] = useState<Node | null>(null); // To store the currently selected node for configuration
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [workflowName, setWorkflowName] = useState<string>(""); // To store the workflow name

  // Modals
  const [openDataUploadModal, setOpenDataUploadModal] = useState(false);
  const [openAnalysisConfigModal, setOpenAnalysisConfigModal] = useState(false);
  const [openGraphMinorNodeModal, setOpenGraphMinorNodeModal] = useState(false);
  const [openScriptingNodeModal, setOpenScriptingNodeModal] = useState(false);

  const handleOpenDataUploadModal = () => setOpenDataUploadModal(true);
  const handleCloseDataUploadModal = () => setOpenDataUploadModal(false);
  const handleOpenGraphMinorNodeModal = () => setOpenGraphMinorNodeModal(true);
  const handleCloseGraphMinorNodeModal = () =>
    setOpenGraphMinorNodeModal(false);
  const handleOpenAnalysisConfigModal = () => setOpenAnalysisConfigModal(true);
  const handleCloseAnalysisConfigModal = () =>
    setOpenAnalysisConfigModal(false);
  const handleOpenScriptingNodeModal = () => setOpenScriptingNodeModal(true);
  const handleCloseScriptingNodeModal = () => setOpenScriptingNodeModal(false);

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const nodeType = event.dataTransfer.getData("nodeType");
    const newNodeId = nodes.length + 1;
    const newNode: Node = {
      id: newNodeId,
      type: nodeType,
      config: {},
      name: nodeType,
    };
    console.log(newNode, "newNode");
    setNodes([...nodes, newNode]);
  };

  const handleDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    nodeType: string
  ) => {
    event.dataTransfer.setData("nodeType", nodeType);

    // Check if a node of the same type already exists
    const existingNodeIndex = nodes.findIndex((node) => node.type === nodeType);

    // Remove the existing node, if found
    if (existingNodeIndex !== -1) {
      const updatedNodes = [...nodes];
      updatedNodes.splice(existingNodeIndex, 1);
      setNodes(updatedNodes);
    }
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleEditConfig = (nodeId: number) => {
    console.log(nodeId);
    const nodeToEdit = nodes.find((node) => node.id === nodeId);
    setSelectedNode(nodeToEdit || null);
    console.log(nodes, nodeToEdit);

    if (nodeToEdit) {
      console.log("nodeToEdit", nodeToEdit.type);
      if (nodeToEdit.type === "Data Upload") {
        handleOpenDataUploadModal();
      } else if (nodeToEdit.type === "Scripting Node") {
        handleOpenScriptingNodeModal();
      } else if (nodeToEdit.type === "GraphMiner Configuration") {
        handleOpenGraphMinorNodeModal();
        console.log("graph minor node modal open", openGraphMinorNodeModal);
      } else if (nodeToEdit.type === "16s Analysis Configuration") {
        handleOpenAnalysisConfigModal();
      }
    }
  };

  const handleSaveConfig = (updatedConfig: any) => {
    console.log(updatedConfig);
    if (!selectedNode) return;
    const updatedNodes = nodes.map((node) =>
      node.id === selectedNode.id
        ? {
            ...node,
            type:
              node.name === "GraphMiner Configuration"
                ? "GraphMiner"
                : node.name === "16s Analysis Configuration"
                ? "Taxonomy"
                : node.type,
            config: updatedConfig,
          }
        : node
    );
    console.log(updatedNodes);
    setNodes(updatedNodes);
    setSelectedNode({ ...selectedNode, config: updatedConfig });
  };

  const handleDeleteNode = (nodeId: number) => {
    const updatedNodes = nodes.filter((node) => node.id !== nodeId);
    setNodes(updatedNodes);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    nodeId: number
  ) => {
    e.stopPropagation(); // Stop propagation only for the delete button
    handleDeleteNode(nodeId);
  };

  const handleNodeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedNode) {
      const updatedNodes = nodes.map((node) =>
        node.id === selectedNode.id
          ? { ...node, name: event.target.value }
          : node
      );
      setNodes(updatedNodes);
      setSelectedNode({ ...selectedNode, name: event.target.value });
    }
  };

  const handleSaveWorkflow = async () => {
    try {
      const formData = new FormData();
      const workflowData = {
        workflowName,
        nodes: nodes.map((node) => ({
          type: node.type,
          config: node.config,
          name: node.name,
        })),
      };
      formData.append("workflowData", JSON.stringify(workflowData));
      console.log("formData===> :  " + formData);
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });
      const headers = {
        "Content-Type": "application/json",
      };
      const mergedHeaders = { ...headers, ...authHeader() };
      const response = await axios.post(
        process.env.REACT_APP_BACKEND + "/api/saveWorkflow",
        formData,
        { headers: mergedHeaders }
      );

      if (response.status === 200) {
        setSaveMessage(response.data);
        setTimeout(() => {
          setSaveMessage(null);
        }, 3000);
        setNodes([]);
        setSelectedNode(null);
        setWorkflowName("");
      } else {
        alert("Server sent error");
        setSaveMessage("Error saving the workflow.");
        setTimeout(() => {
          setSaveMessage(null);
        }, 3000);
      }
    } catch (error: any) {
      if (error.response) {
        setSaveMessage(error.response.data);
        setTimeout(() => {
          setSaveMessage(null);
        }, 10000);
      }
      console.error(error);
    }
  };

  console.log("workflow builder open", nodes);

  return (
    <div className="workflow-builder">
      <div className="workflow-header">
        <div className="workflow-header-row">
          <input
            type="text"
            value={workflowName}
            onChange={(e) => setWorkflowName(e.target.value)}
            placeholder="Enter Workflow Name"
          />
          <button id="save-workflow-btn" onClick={handleSaveWorkflow}>
            Save Workflow
          </button>
        </div>
      </div>
      <div className="workflow-content">
        <div className="workflow-sidebar">
          <Tooltip
            title="Allow the user to click on them and select data input files such as .CSV files which are residing in Amazon S3 storage. Users can select files or multiple files."
            placement="right"
          >
            <div
              className="workflow-node"
              draggable
              onDragStart={(event) => handleDragStart(event, "Data Upload")}
              style={{ cursor: "pointer" }}
            >
              Data Upload
            </div>
          </Tooltip>
          <Tooltip
            title="Allows the user to select a python script, R script to be selected which are residing in Amazon S3 storage."
            placement="right"
          >
            <div
              className="workflow-node"
              draggable
              onDragStart={(event) => handleDragStart(event, "Scripting Node")}
              style={{ cursor: "pointer" }}
            >
              Scripting Node
            </div>
          </Tooltip>
          <Tooltip
            title="Allows the user to take the output of Scripting Node or Data Upload and push it into a user graph database to be analysed further. This is an endpoint used to move structured csv data into Neo4J."
            placement="right"
          >
            <div
              className="workflow-node"
              draggable
              onDragStart={(event) => handleDragStart(event, "Push To Neo4j")}
              style={{ cursor: "pointer" }}
            >
              Push to Neo4j
            </div>
          </Tooltip>
          <Tooltip
            title="Our internal proprietary algorithm for extracting knowledge from tabular datasets that are binary classification tasks. Graphminer accepts datasets as a CSV where the features are stored in the columns and are real valued numbers and a target column contains the class labels as 0s and 1s. The output of Graphminer is a set of boolean rules that together explain the training dataset that was uploaded to the program."
            placement="right"
          >
            <div
              className="workflow-node"
              draggable
              onDragStart={(event) =>
                handleDragStart(event, "GraphMiner Configuration")
              }
              style={{ cursor: "pointer" }}
            >
              GraphMiner Configuration
            </div>
          </Tooltip>
          <Tooltip
            title="The '16S' in the Graphomics platform is designed to support workflows that analyse 16S rRNA gene sequencing data, which is commonly used in microbiome research to identify and classify bacterial species within a sample. Provide a directory, a forward read regex, backward reverse read regex, the database you would like to search against (Silva, HOMD) and the output directory."
            placement="right"
          >
            <div
              className="workflow-node"
              draggable
              onDragStart={(event) =>
                handleDragStart(event, "16s Analysis Configuration")
              }
              style={{ cursor: "pointer" }}
            >
              16s Analysis Configuration
            </div>
          </Tooltip>
        </div>
        <div
          className={`workflow-canvas ${isDraggingOver ? "dragging-over" : ""}`}
          onDrop={handleDrop}
          onDragOver={handleDragEnter}
          onDragLeave={handleDragLeave}
        >
          {nodes.map((node) => (
            <div
              key={node.id}
              className={`workflow-node ${
                selectedNode === node ? "selected" : ""
              }`}
              onClick={() => handleEditConfig(node.id)}
            >
              <div>
                <div>{node.name}</div>
                {node?.config?.datafile && (
                  <small>
                    Selected Files: {node.config.datafile.join(", ")}
                  </small>
                )}
                {Array.isArray(node?.config) ||
                typeof node?.config === "string" ? (
                  <small>Selected Files: {node.config?.toString()}</small>
                ) : null}
              </div>
              <button
                className="delete-btn"
                onClick={(e) => handleDelete(e, node.id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        {saveMessage && <div className="save-message">{saveMessage}</div>}

        {/* <DataUploadModal
          onSave={(config) => handleSaveConfig(config)}
          onClose={handleCloseDataUploadModal}
          open={openDataUploadModal}
        /> */}
        {openDataUploadModal && (
          <FileSelector
            onSave={(file) => handleSaveConfig(file)}
            onClose={handleCloseDataUploadModal}
            open={openDataUploadModal}
            // checkSelectable={(file) => /\.(csv)$/i.test(file.name)}
            defaultSearch=".(csv)$"
          />
        )}

        {openGraphMinorNodeModal && (
          <GraphMinorNodeModal
            onSave={(config) => handleSaveConfig(config)}
            onClose={handleCloseGraphMinorNodeModal}
            open={openGraphMinorNodeModal}
          />
        )}
        {openAnalysisConfigModal && (
          <AnalysisConfigNodeModal
            onSave={(config) => handleSaveConfig(config)}
            onClose={handleCloseAnalysisConfigModal}
            open={openAnalysisConfigModal}
          />
        )}
        {/* <ScriptingNodeModal
          onSave={(config) => handleSaveConfig(config)}
          onClose={handleCloseScriptingNodeModal}
          open={openScriptingNodeModal}
        /> */}
        {openScriptingNodeModal && (
          <FileSelector
            title="Select a Script (Python or R)"
            onSave={(file) => {
              if (file.length > 0) {
                handleSaveConfig(file[0]);
              }
            }}
            onClose={handleCloseScriptingNodeModal}
            open={openScriptingNodeModal}
            selectionMode="single"
            defaultSearch=".(py|r)$"
          />
        )}
      </div>
    </div>
  );
};

export default WorkflowBuilder;
