import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getAllFilesCached } from "../../misc/helpers";
import FileBrowser from "./FileBrowser";
import CloseIcon from "@mui/icons-material/Close";
import { FileData } from "chonky";

interface IFileSelectorProps {
  onSave: (file: string[]) => void;
  onClose: () => void;
  open: boolean;
  selectionMode?: "single" | "multiple";
  checkSelectable?: (file: FileData) => boolean;
  filterFiles?: (files: FileData[]) => FileData[];
  title?: React.ReactNode;
  defaultSearch?: string;
}
export default function FileSelector(props: IFileSelectorProps) {
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  let isUploadEnabled = selectedFiles.length > 0;
  if (props.selectionMode === "single") {
    isUploadEnabled = selectedFiles.length === 1;
  }

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {props.title ?? "Select a File"}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            marginLeft: "auto",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={{}}>
        <FileBrowser
          onFileSelection={setSelectedFiles}
          checkSelectable={props.checkSelectable}
          filterFiles={props.filterFiles}
          defaultSearch={props.defaultSearch}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={!isUploadEnabled}
          onClick={() => {
            props.onSave(selectedFiles);
            props.onClose();
          }}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
}
