import { ChonkyIconName, FileData } from "chonky";

import { defineFileAction } from "chonky";

export const previewFile = defineFileAction({
  id: "Preview",
  requiresSelection: true,
  fileFilter: (file: any) => !file.isDir,
  button: {
    name: "Preview",
    toolbar: false,
    contextMenu: true,
    icon: ChonkyIconName.info,
  },
});

export const downloadFile = defineFileAction({
  id: "Download",
  requiresSelection: true,
  // fileFilter: (file: any) => !file.isDir,
  button: {
    name: "Download",
    toolbar: false,
    contextMenu: true,
    icon: ChonkyIconName.download,
  },
});

export const deleteFile = defineFileAction({
  id: "Delete",
  requiresSelection: true,
  // fileFilter: (file: any) => !file.isDir,
  button: {
    name: "Delete",
    toolbar: false,
    contextMenu: true,
    icon: ChonkyIconName.trash,
  },
});

export const renameFileAction = defineFileAction({
  id: "Rename",
  requiresSelection: true,
  button: {
    name: "Rename",
    toolbar: false,
    contextMenu: true,
    icon: ChonkyIconName.code,
  },
});
