import { Button, TextField } from "@mui/material";
import {
  ChonkyActions,
  ChonkyFileActionData,
  ChonkyIconName,
  FileAction,
  FileActionHandler,
  FileArray,
  FileBrowser,
  FileContextMenu,
  FileData,
  FileList,
  FileNavbar,
  FileToolbar,
} from "chonky";
import { saveAs } from "file-saver";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { openGlobalDialog } from "../../common/GlobalDialog";
import {
  hideGlobalLoading,
  setGlobalLoadingProgress,
  showGlobalLoading,
  withLoadingError,
} from "../../common/GlobalLoading";
import useObjectState from "../../hooks/useObjectState";
import Modal from "../../material_ui/Model";
import { ApiService } from "../../services/ApiService";
import { showError } from "../../utils/alertUtils";
import DropOverlay from "./components/DropOverlay";
import {
  deleteFile,
  downloadFile,
  previewFile,
  renameFileAction,
} from "./FileActions";
import { openFilePreviewDialog } from "./FilePreviewDialog";
import { getFileColor, getFiles } from "./helpers";

// Define styles as an object

const FileBrowserComponent = FileBrowser as React.ComponentType<any>;

const MainComponent: React.FC = () => {
  const [files, setFiles] = useState<FileArray>([]);
  const [folderChain, setFolderChain] = useState<FileArray>([]);
  const [selectedFiles, setSelectedFiles] = useState<FileData[]>([]);
  // const [prefix, setPrefix] = useState<string>("");
  // const [search, setSearch] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [fileext, setFileExt] = useState<any>({});

  const filters = useObjectState<{
    search: string;
    prefix: string;
  }>({
    search: "",
    prefix: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleNewFolderSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    const folderName = formJson.email;
    if (folderName) {
      try {
        showGlobalLoading();
        // Construct the prefix for the nested folder
        let folderPrefix = filters.state.prefix;
        if (filters.state.prefix === "root") {
          folderPrefix = "";
        }
        console.log(folderPrefix + folderName);
        // Create the nested folder in the S3 bucket
        let res = await ApiService.createFolder(`${folderPrefix + folderName}`);

        console.log("Folder created successfully:", res);

        // Refresh file list after creating folder
        await fetchFilesAndFolders(filters.state.prefix, { refetch: true });
      } catch (error) {
        console.error("Error creating folder:", error);
      } finally {
        hideGlobalLoading();
      }
    }
    handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchFilesAndFolders = async (
    newPrefix: string = "",
    options: { refetch?: boolean } = {}
  ) => {
    try {
      showGlobalLoading();

      const fls = await getFiles(newPrefix, {
        refetch: options.refetch,
        search: filters.state.search,
      });
      setFiles(fls);
      if (filters.state.prefix !== newPrefix) {
        filters.setState({
          prefix: newPrefix === "root" ? "" : newPrefix,
          search: filters.state.search,
        });
      }
    } catch (error) {
      showError(error);
    } finally {
      hideGlobalLoading();
    }
  };

  // useEffect(() => {
  //   if (files.length > 0) setFileExt({});
  //   files.map((data: any) => {
  //     if (!data) return;
  //     if (data.isDir) {
  //       setFileExt((prevState: any) => ({
  //         ...prevState,
  //         ["folder"]: "rgb(187, 187, 187)",
  //       }));
  //     } else {
  //       let getfileext: string = getFileExtension(data.id) || "txt";

  //       setFileExt((prevState: any) => ({
  //         ...prevState,
  //         [getfileext]: getFileColor[getfileext],
  //       }));
  //     }
  //   });
  // }, [files]);
  const handleFileAction: FileActionHandler = useCallback(
    async (data: ChonkyFileActionData) => {
      // console.log("File action triggered:", data);
      if (data.id === downloadFile.id) {
        console.log("Downloading file:", data.state);

        if (data.state.contextMenuTriggerFile?.id) {
          const file = data.state.contextMenuTriggerFile;
          if (file.isDir) {
            await withLoadingError(async () => {
              let res = await ApiService.downloadMultipleFiles(file.id);
              saveAs(res.data, file.name);
            });
          } else {
            let link = ApiService.prepareDownloadLink(file.id || "");
            window.open(link, "_blank");
            // await withLoadingError(async () => {
            //   setGlobalLoadingProgress(0);
            //   let res = await ApiService.downloadSingleFile(file.id, (p) => {
            //     setGlobalLoadingProgress(p);
            //   });
            //   saveAs(res.data, file.name);
            // });
          }
        }
      } else if (data.id === deleteFile.id) {
        withLoadingError(async () => {
          if (data.state.selectedFiles) {
            const file = data.state.selectedFiles;
            if (file) {
              const filesToDelete = data.state.selectedFiles
                .filter((item) => !item.isDir)
                .map((file) => ({
                  Key: file.id,
                }));
              const foldersToDelete = data.state.selectedFiles
                .filter((item) => item.isDir)
                .map((file) => ({
                  Key: file.id,
                }));
              if (filesToDelete.length > 0) {
                let res = await ApiService.deleteFiles(
                  filesToDelete.map((file) => file.Key)
                );

                console.log("File deleted successfully:", res);
              }
              if (foldersToDelete.length > 0) {
                for (let folder of foldersToDelete) {
                  let res = await ApiService.deleteFolder(folder.Key);
                  console.log("Folder deleted successfully:", res);
                }
              }
              // Refresh file list after deletion
              await fetchFilesAndFolders(filters.state.prefix, {
                refetch: true,
              });
            }
          } else {
            console.log("No files in selectedFiles:", data.state.selectedFiles);
            showError("No files selected");
          }
        });
      } else if (data.id === renameFileAction.id) {
        console.log(
          "Rename file triggered:",
          data.state.contextMenuTriggerFile?.id
        );
        if (data.state.contextMenuTriggerFile?.id) {
          let modal = openGlobalDialog({
            title: "Rename File",
            body: (
              <div>
                <TextField
                  fullWidth
                  label="New Name"
                  defaultValue={data.state.contextMenuTriggerFile.id}
                  id="rename-file-input"
                  autoFocus
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const newName = (
                      document.getElementById("rename-file-input") as any
                    )?.value;
                    if (!newName) {
                      showError("New name is required");
                      return;
                    }
                    withLoadingError(async () => {
                      if (data.state.contextMenuTriggerFile?.isDir) {
                        await ApiService.renameFolder(
                          data.state.contextMenuTriggerFile?.id || "",
                          newName
                        );
                      } else {
                        await ApiService.renameFile(
                          data.state.contextMenuTriggerFile?.id || "",
                          newName
                        );
                      }
                      await fetchFilesAndFolders(filters.state.prefix, {
                        refetch: true,
                      });
                      modal.onClose();
                    });
                  }}
                >
                  Rename
                </Button>
              </div>
            ),
          });
        }
      } else if (data.id === ChonkyActions.UploadFiles.id) {
        document.getElementById("fileInput")?.click();
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        // const folderName = prompt('Enter folder name:');
        handleClickOpen();
      } else if (data.id === ChonkyActions.OpenFiles.id) {
        const fileToOpen = data.payload.targetFile;
        if (fileToOpen && fileToOpen.isDir) {
          console.log("Opening folder:", fileToOpen);

          // setTimeout(() => {
          //   filters.setState({ search: "" });
          // }, 100);

          fetchFilesAndFolders(fileToOpen.id);
        } else {
          if (data.payload.targetFile) {
            openFilePreviewDialog({
              s3ID: data.payload.targetFile.id,
              name: data.payload.targetFile.name,
            });
          }
        }
      } else if (data.id === previewFile.id) {
        let fileToOpen = data.state.contextMenuTriggerFile?.id;
        if (fileToOpen) {
          openFilePreviewDialog({
            s3ID: fileToOpen,
            name: data.state.contextMenuTriggerFile?.name || "",
          });
        }
      }
    },
    [folderChain]
  );
  useEffect(() => {
    updateFolderChain(filters.state.prefix);
  }, [filters.state.prefix]);

  useEffect(() => {
    fetchFilesAndFolders(filters.state.prefix);
  }, [filters.state.search]);
  // useEffect(() => {
  //   // console.log(fileext);
  // }, [fileext]);
  const updateFolderChain = (prefix: string) => {
    const parts = prefix.split("/");
    let accumulatedPath = "";
    const folderChain = parts
      .map((part: string, index: number) => {
        if (index < parts.length - 1) {
          accumulatedPath += part + "/";
        } else {
          accumulatedPath += part;
        }
        return { id: accumulatedPath, name: part, isDir: true };
      })
      .filter((item) => !!item.name && !!item.id);

    setFolderChain([{ id: "root", name: "Root", isDir: true }, ...folderChain]);
  };
  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;

    try {
      showGlobalLoading();
      let newPrefix = filters.state.prefix;
      if (filters.state.prefix === "root") {
        newPrefix = "";
      }
      await ApiService.uploadFile(Array.from(files), newPrefix);
      fetchFilesAndFolders(newPrefix, { refetch: true });
    } catch (error) {
      showError(error);
    } finally {
      hideGlobalLoading();
      event.target.value = "";
    }
  };

  const handleSelectionChange = (
    fileAction: FileAction,
    selectedFiles: FileData[]
  ) => {
    console.log("Selection changed:", selectedFiles);
    setSelectedFiles(selectedFiles);
  };

  useEffect(() => {
    fetchFilesAndFolders();
  }, []);
  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    document.getElementById("drop-overlay")!.style.display = "none";
    const dataFiles = event.dataTransfer.files;
    if (!dataFiles) return;

    try {
      showGlobalLoading();
      await ApiService.uploadFile(Array.from(dataFiles), filters.state.prefix);
      await fetchFilesAndFolders(filters.state.prefix, { refetch: true });
    } catch (error) {
      showError(error);
    } finally {
      hideGlobalLoading();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    document.getElementById("drop-overlay")!.style.display = "flex";
  };

  return (
    <>
      <div
        style={{
          height: "80vh",
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={(event) => {
          // Check if the drag leave event is actually leaving the container
          // by checking if we're entering a child element
          if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            document.getElementById("drop-overlay")!.style.display = "none";
          }
        }}
      >
        <DropOverlay id="drop-overlay" />
        <Modal {...{ open, handleNewFolderSubmit, handleClose }} />
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={(e: any) => handleFileChange(e)}
        />
        <DndProvider backend={HTML5Backend}>
          <FileBrowserComponent
            files={files}
            folderChain={folderChain}
            fileActions={[
              previewFile,
              downloadFile,
              deleteFile,
              renameFileAction,
              ChonkyActions.UploadFiles,

              ChonkyActions.CreateFolder,
              {
                ...ChonkyActions.OpenSelection,
                button: {
                  name: "Preview",
                  toolbar: false,
                  contextMenu: false,
                  icon: ChonkyIconName.info,
                },
              },
            ]}
            onFileAction={handleFileAction}
          >
            <FileNavbar />
            <FileToolbar
              onSearchChange={(value) => {
                console.log("search", value);
                filters.setState({ search: value });
              }}
              searchValue={filters.state.search}
            />
            <FileList />
            <FileContextMenu />
          </FileBrowserComponent>
        </DndProvider>
      </div>
      <div className="legend-box">
        {Object.keys(fileext).map((name) => {
          return (
            <div
              key={name}
              style={{ display: "flex", gap: "4px", alignItems: "center" }}
            >
              <div
                style={{
                  height: "12px",
                  width: "20px",
                  backgroundColor: getFileColor[name] || getFileColor["txt"],
                  boxShadow: "rgba(255, 255, 255, 0.4) 0px 0px 0px 999px inset",
                }}
              ></div>
              <span>{name}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MainComponent;
