import { Alert, CircularProgress, LinearProgress } from "@mui/material";
import { parseErrorString } from "../utils/alertUtils";

export default function ReactQueryLoadingError(props: {
  q: any;
  LoadingProperty?: "isLoading" | "isFetching";
  progress?: number;
}) {
  let LoadingProperty = props.LoadingProperty || "isFetching";
  if (!props.q[LoadingProperty]) {
    LoadingProperty = "isLoading";
  }
  return (
    <>
      {props.q[LoadingProperty] ? (
        <div>
          {props.progress !== undefined ? (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <LinearProgress
                variant="determinate"
                value={props.progress}
                style={{ flexGrow: 1 }}
              />
              <span>{Math.round(props.progress)}%</span>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      ) : (
        <>
          {props.q.isError && (
            <Alert severity="error">{parseErrorString(props.q.error)}</Alert>
          )}
        </>
      )}
    </>
  );
}
